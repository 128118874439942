import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f69ad908"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "customization-container"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "round-name"
};
const _hoisted_4 = {
  class: "food-options"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "food-name"
};
const _hoisted_8 = {
  class: "footer"
};
import { ref, onMounted } from 'vue';
import { useOrderStore } from '@/stores/orderStore';
import { storeToRefs } from 'pinia';
import FlavorCustomizationDialog from '@/components/FlavorCustomizationDialog';
import MainDishCustomizationDialog from '@/components/FlavorCustomizationDialog/main-dish.vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'customization',
  props: {
    currentProductIndex: {
      type: Number,
      required: true
    }
  },
  emits: ['close', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const customizationDialogVisible = ref(false);
    const mainDishDialogVisible = ref(false);
    const selectedItem = ref(null);
    const orderStore = useOrderStore();
    const {
      selectedProduct,
      selectedItems
    } = storeToRefs(orderStore);
    const props = __props;
    const emit = __emit;
    const closeCustomization = () => {
      emit('close');
    };
    const isSelected = (roundId, itemSkuCode) => {
      return selectedItems.value[props.currentProductIndex]?.[roundId]?.includes(itemSkuCode);
    };
    const selectFood = (roundId, itemSkuCode) => {
      const selected = selectedItems.value[props.currentProductIndex][roundId] || [];
      const round = selectedProduct.value.productSelectDetail.rounds.find(r => r.id === roundId);
      const maxSelectable = round.saleQty || round.items.length;
      const index = selected.indexOf(itemSkuCode);
      if (maxSelectable === 1) {
        selectedItems.value[props.currentProductIndex][roundId] = [itemSkuCode];
      } else {
        if (index > -1) {
          selected.splice(index, 1);
        } else {
          if (selected.length < maxSelectable) {
            selected.push(itemSkuCode);
          } else {
            ElMessage({
              message: `最多只能选择 ${maxSelectable} 个`,
              type: 'warning'
            });
          }
        }
        selectedItems.value[props.currentProductIndex][roundId] = [...selected];
      }
    };
    const selectedCount = roundId => {
      return selectedItems.value[props.currentProductIndex]?.[roundId]?.length || 0;
    };

    // 修改 openCustomization 函数
    const openCustomization = item => {
      if (item.productAttributes) {
        selectedItem.value = item;
        customizationDialogVisible.value = true;
      }
    };

    // 新增 openMainDishCustomization 函数
    const openMainDishCustomization = item => {
      if (item.rounds && item.rounds.some(round => round.type === 2)) {
        selectedItem.value = item;
        mainDishDialogVisible.value = true;
      }
    };
    const confirmSelection = () => {
      orderStore.setSelectedItems(selectedItems.value);
      emit('confirm', {
        selectedItems: selectedItems.value
      });
      emit('close');
    };
    const handleCustomizationConfirm = ({
      item,
      selectedAttributes
    }) => {
      item.productAttributes.forEach(attribute => {
        attribute.items.forEach(option => {
          option.isPush = 1;
          if (selectedAttributes[attribute.id] === String(option.id)) {
            option.isPush = 0;
          }
        });
      });
      customizationDialogVisible.value = false;
    };
    const handleMainDishCustomizationConfirm = ({
      item,
      selectedOptions
    }) => {
      item.rounds.forEach(round => {
        if (round.type === 2) {
          round.items.forEach(option => {
            option.isDefault = selectedOptions[round.id].includes(String(option.id)) ? 'Y' : 'N';
          });
        }
      });
      mainDishDialogVisible.value = false;
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createBlock(_Transition, {
        name: "slide-up"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "overlay",
          onClick: _withModifiers(closeCustomization, ["self"])
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h3", null, _toDisplayString(_unref(selectedProduct).productName), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedProduct).productSelectDetail?.rounds, round => {
          return _openBlock(), _createElementBlock("div", {
            class: "selection-section",
            key: round.id
          }, [_createElementVNode("p", _hoisted_3, _toDisplayString(round.roundName) + " (已选 " + _toDisplayString(selectedCount(round.id)) + " / 可选 " + _toDisplayString(round.saleQty ? round.saleQty : round.items.length) + ")", 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(round.items, item => {
            return _openBlock(), _createElementBlock("div", {
              key: item.itemSkuCode,
              class: _normalizeClass(['food-option', {
                selected: isSelected(round.id, item.itemSkuCode)
              }]),
              onClick: $event => selectFood(round.id, item.itemSkuCode)
            }, [_createElementVNode("img", {
              src: item.mainImageUrl,
              alt: "Food Image"
            }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, _toDisplayString(item.itemSkuName), 1), item.productAttributes ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              size: "small",
              type: "success",
              onClick: _withModifiers($event => openCustomization(item), ["stop"]),
              class: "customization-button"
            }, {
              default: _withCtx(() => [_createTextVNode("口味定制")]),
              _: 2
            }, 1032, ["onClick"])) : _createCommentVNode("", true), item.rounds && item.rounds.some(round => round.type === 2) ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              size: "small",
              type: "primary",
              onClick: _withModifiers($event => openMainDishCustomization(item), ["stop"]),
              class: "customization-button"
            }, {
              default: _withCtx(() => [_createTextVNode("主食定制")]),
              _: 2
            }, 1032, ["onClick"])) : _createCommentVNode("", true)], 10, _hoisted_5);
          }), 128))])]);
        }), 128)), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_button, {
          type: "success",
          class: "confirm-button",
          round: "",
          onClick: confirmSelection
        }, {
          default: _withCtx(() => [_createTextVNode("选好了")]),
          _: 1
        })]), customizationDialogVisible.value ? (_openBlock(), _createBlock(_unref(FlavorCustomizationDialog), {
          key: 0,
          item: selectedItem.value,
          onClose: _cache[0] || (_cache[0] = $event => customizationDialogVisible.value = false),
          onConfirm: handleCustomizationConfirm
        }, null, 8, ["item"])) : _createCommentVNode("", true), mainDishDialogVisible.value ? (_openBlock(), _createBlock(MainDishCustomizationDialog, {
          key: 1,
          item: selectedItem.value,
          onClose: _cache[1] || (_cache[1] = $event => mainDishDialogVisible.value = false),
          onConfirm: handleMainDishCustomizationConfirm
        }, null, 8, ["item"])) : _createCommentVNode("", true)])])]),
        _: 1
      });
    };
  }
};